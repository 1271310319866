import React, { useEffect, useState } from "react";
import TodoList from "./todo/TodoList";
import ChecklistQuestions from "./checklist-questions";
import { getUser } from "../../services/userService"
import "./checklist.css";

const Checklist = () => {
  const [hasCompletedChecklistQuestions, setHasCompletedChecklistQuestions] = useState(null);


  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser();
      setHasCompletedChecklistQuestions(user?.hasCompletedChecklistQuestions);
    };
    fetchUser();
  }, []); 
  

  if (hasCompletedChecklistQuestions) {
    return (<TodoList/>); 
  }
  else {
    return (<ChecklistQuestions setHasCompletedChecklistQuestions={setHasCompletedChecklistQuestions} />)
  }

}

export default Checklist;;