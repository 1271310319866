import React from "react";
import "./navigation.css";

const Navigation = ({ userInfo, activeTab, setActiveTab }) => {
  return (
    <header className="dashboard-header">
      <div className="dashboard-nav-container">
        <div className="dashboard-info">
          <div>
            {userInfo?.userName} & {userInfo?.partnerName}'s Wedding{" "}
          </div>
          <div className="dashboard-days-left">189 Days to go</div>
        </div>
        <nav className="dashboard-nav">
        <div
            className={activeTab === 'event-manager' ? 'active' : ''}
            onClick={() => setActiveTab('event-manager')}
          >
          ‍🙋🏽‍♀️ EVENT MANAGER
          </div>
          <div
            className={activeTab === 'checklist' ? 'active' : ''}
            onClick={() => setActiveTab('checklist')}
          >
            📝 CHECKLIST
          </div>
          <div
            className={activeTab === 'cost-planner' ? 'active' : ''}
            onClick={() => setActiveTab('cost-planner')}
          >
            💵 COST PLANNER
          </div>

        </nav>
      </div>
      <hr className="full-width-line" />
    </header>
  );
};

export default Navigation;
