const cities = [
  // Top Cities in the United States
  "New York, NY", "Los Angeles, CA", "Chicago, IL", "Houston, TX",
  "Phoenix, AZ", "Philadelphia, PA", "San Antonio, TX", "San Diego, CA",
  "Dallas, TX", "San Jose, CA", "Austin, TX", "Jacksonville, FL",
  "Fort Worth, TX", "Columbus, OH", "Charlotte, NC", "San Francisco, CA",
  "Indianapolis, IN", "Seattle, WA", "Denver, CO", "Washington, DC",
  "Boston, MA", "El Paso, TX", "Nashville, TN", "Detroit, MI",
  "Oklahoma City, OK", "Portland, OR", "Las Vegas, NV", "Memphis, TN",
  "Louisville, KY", "Baltimore, MD", "Milwaukee, WI", "Albuquerque, NM",
  "Tucson, AZ", "Fresno, CA", "Mesa, AZ", "Sacramento, CA",
  "Atlanta, GA", "Kansas City, MO", "Colorado Springs, CO", "Miami, FL",

  // Top Cities in Canada
  "Toronto, ON", "Montreal, QC", "Vancouver, BC", "Calgary, AB",
  "Edmonton, AB", "Ottawa, ON", "Winnipeg, MB", "Quebec City, QC",
  "Hamilton, ON", "Kitchener, ON"
];

const religions = [
  { value: 'hindu', label: "Hindu" },
  { value: 'muslim', label: "Muslim" },
  { value: 'ismaili', label: "Ismaili" },
  { value: 'buddhist', label: "Buddhist" },
  { value: 'sikh', label: "Sikh" },
  { value: 'other', label: "Other" },
  { value: 'none', label: "None" },
  { value: 'prefer_not_to_say', label: "Prefer not to say" },
]

const communities = [
  { value: 'gujarati', label: "Gujarati" },
  { value: 'punjabi', label: "Punjabi" },
  { value: 'tamil', label: "Tamil" },
  { value: 'telugu', label: "Telugu" },
  { value: 'hindi', label: "Hindi" },
  { value: 'other', label: "Other" },
  { value: 'none', label: "None" },
  { value: 'prefer_not_to_say', label: "Prefer not to say" },
]

export const venueOptions = ['Hotel', 'Banquet Hall', 'Place of Worship', 'At my home', 'Resort', 'Community Center', 'Other']

export const eventsQuestionId = 28
export const finalStepIndex = 32; 


export const venueOptionsValueMap = {
    welcome_event: '👋🏽 Welcome Event',
    haldi: '🍯 Haldi',
    sangeet: '🎶 Sangeet',
    reception: '🪩 Reception',
    other: '🤔 Other'
}

export const emojiEventMapping = {
  welcomeEvent: '👋🏽',
  haldi: '🍯',
  sangeet: '🎶',
  reception: '🪩',
  wedding: '💍',
  other: '🤔',

}



export const onboarding = [
  {
    id: 2,
    screenType: "form",
    text: "Let's start with the basics, shall we?",
    subText: "Tell me about yourself!",
    nameId: "basicInfo",
    inputs: [
      {
        inputType: "text",
        name: "userName",
        placeholder: "Your Name",
        label: "Primary Name",
        nameId: "userName"
      },
      {
        inputType: "select",
        name: "userHometown",
        placeholder: "Your Hometown",
        label: "Home Town",
        options: cities,
        nameId: "userHometown"
      }
    ]
  },
  {
    id: 3,
    screenType: "form",
    text: "And who's the special someone? 👩‍❤️‍👨",
    subText: "I'm excited to get to know them!",
    nameId: "partnerInfo",
    inputs: [
      {
        inputType: "text",
        name: "partnerName",
        placeholder: "Partner's Name",
        label: "Partner's Name",
        nameId: "partnerName"
      },
      {
        inputType: "select",
        name: "partnerHometown",
        placeholder: "Their Hometown",
        label: "Their Hometown",
        options: cities,
        nameId: "partnerHometown"
      }
    ]
  },
  {
    id: 4,
    screenType: "multi-select",
    text: "How far along are you in your planning process?",
    nameId: "planningProgress",
    inputs: [
      {
        inputType: "multi-select",
        name: "planningProgress",
        maxSelections: 1,
        options: [
          { value: "notDiscussed", label: "🙃 Haven’t even talked about it", nameId: "notDiscussed" },
          { value: "discussed", label: "💬 We’ve had conversations", nameId: "discussed" },
          { value: "planning", label: "🤔 A plan is in the works", nameId: "planning" },
          { value: "vendorsFinalized", label: "💰 We’ve finalized vendors", nameId: "vendorsFinalized" },
          { value: "none", label: "🤷🙅🏽‍♀️ None of the above", nameId: "none" }
        ],
        nameId: "planningProgressInput"
      }
    ]
  },
  {
    id: 5,
    screenType: "info",
    text: "You’re in the right place!",
    subText: "My goal is to eliminate the gate keeping that happens in the Indian wedding industry. \n\n I’ve seen a lot of weddings, so I’ll be able to help you: \n\n 1. Plan for unexpected costs \n 2. Find ways to save \n 3. Lay out all the to-do’s for your situation",
    nameId: 'valueProp'
  },
  {
    id: 6,
    screenType: "form",
    text: "Where & when are you planning to get married?",
    nameId: "weddingDetails",
    inputs: [
      {
        inputType: "select",
        name: "weddingCity",
        placeholder: "City",
        label: "City",
        options: cities,
        nameId: "weddingCity"
      },
      {
        inputType: "date",
        name: "weddingDate",
        placeholder: "Date (MM/YYYY)",
        label: "Wedding Date",
        options: venueOptions,
        nameId: "weddingDate"
      }
    ]
  },
  {
    id: 7,
    screenType: "form",
    text: "Do you know who’s invited & what the budget is?",
    nameId: "guestBudget",
    inputs: [
      {
        inputType: "number",
        name: "guestCount",
        placeholder: "Estimated Guest Count",
        label: "Estimated Guest Count",
        nameId: "guestCount"
      },
      {
        inputType: "number",
        name: "budgetEstimate",
        placeholder: "Ballpark Budget",
        label: "Ballpark Budget",
        nameId: "budgetEstimate"
      }
    ]
  },
  {
    id: 17,
    screenType: "multi-select",
    text: "Besides the wedding, which other events would you host?",
    nameId: "additionalEvents",
    inputs: [
      {
        inputType: "multi-select",
        name: "additionalEvents",
        maxSelections: 5,
        options: [
          { value: "welcomeEvent", label: "👋🏽 Welcome Event", nameId: "welcomeEvent" },
          { value: "haldi", label: "🍯 Haldi", nameId: "haldi" },
          { value: "sangeet", label: "🎶 Sangeet", nameId: "sangeet" },
          { value: "reception", label: "🪩 Reception", nameId: "reception" },
          { value: "other", label: "🤔 Other", nameId: "other" }
        ],
        nameId: "additionalEventsInput"
      }
    ]
  },


  {
    id: finalStepIndex,
    collectEmailStep: true,
    screenType: "form",
    text: "By my calculations, you’ll be over budget by $50,300! 😲 But I found a few ways to save big time!",
    nameId: "finalStep",
    inputs: [
      {
        inputType: "text",
        name: "email",
        placeholder: "Email Address",
        nameId: "email"
      },
      {
        inputType: "password",
        name: "password",
        placeholder: "Password",
        nameId: "password"
      }
    ]
  }
]
