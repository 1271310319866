import React, { useState, useEffect } from "react";
import { emojiEventMapping } from "../../utils/onboarding-questions";
import { apiService } from "../../utils/apiService";
import { getEventInfo } from "../../services/userService";
import { vendors } from "../../utils/constants";
import "./event-details.css";

const EventDetails = () => {
  const [eventInfo, setEventInfo] = useState({});
  const [eventNames, setEventNames] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [details, setDetails] = useState({});
  const [vendorsNeeded, setVendorsNeeded] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const [savedEvents, setSavedEvents] = useState(new Set());

  useEffect(() => {
    const fetchEventInfo = async () => {
      const updatedEventInfo = await getEventInfo();
      setEventInfo(updatedEventInfo);
      const eventNames = Object.keys(updatedEventInfo);
      setEventNames(eventNames);
      setSelectedEvent(eventNames[0]);
      setSavedEvents(new Set(eventNames.filter(event => updatedEventInfo[event].userHasSaved)));
    };
    fetchEventInfo();
  }, []);

  useEffect(() => {
    if (selectedEvent && eventInfo[selectedEvent]) {
      const event = eventInfo[selectedEvent];
      setDetails({
        name: event.name,
        dateTime: event.dateTime || "",
        venue: event.venue || "",
        guestCount: event.guestCount || "",
      });

      const vendorCategories = event.costBreakdowns ? event.costBreakdowns.map((item) => item.category) : [];
      setVendorsNeeded(
        vendorCategories.reduce((acc, category) => {
          acc[category.toLowerCase().replace(/ /g, "").replace(/&/g, "And")] = true;
          return acc;
        }, {})
      );
    }
  }, [selectedEvent, eventInfo]);

  const handleDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
    setIsEdited(true);
  };

  const handleVendorChange = (vendorKey) => {
    setVendorsNeeded((prevVendors) => {
      const newVendors = { ...prevVendors, [vendorKey]: !prevVendors[vendorKey] };
      setIsEdited(true);
      return newVendors;
    });
  };

  const handleEventSelection = (event) => {
    setSelectedEvent(event);
    setIsEdited(false);
  };

  const handleSave = async () => {
    const userId = localStorage.getItem('userid'); // Assuming the userid is stored in localStorage
    const eventId = selectedEvent;
    const updatedEvent = {
      ...eventInfo[selectedEvent],
      ...details,
      costBreakdowns: Object.keys(vendorsNeeded).map((vendor) => ({
        category: vendor

      })),
      userHasSaved: true,
    };

    try {
      const response = await apiService.post(`api/user/updateEvent`, {
        userId,
        event: updatedEvent,
        eventId,
      });

      if (response.success) {
        console.log("Event updated successfully");
        setSavedEvents(prev => new Set(prev).add(eventId)); // Mark event as saved
        setIsEdited(false);
      } else {
        console.error("Failed to update event", response.error);
      }
    } catch (error) {
      console.error("Error updating event", error);
    }
  };

  return (
    <div className="event-details-container">
      <div className="event-details-header">Setup Your Personalized Checklist</div>
      <div className="grid-container">
        <div className="event-list">
          {eventNames.map((event) => (
            <div
              key={event}
              className={`event-item ${selectedEvent === event ? "selected" : ""}`}
              onClick={() => handleEventSelection(event)}
            >
              <span className="event-icon">
                {emojiEventMapping[event] || '🎉'} {' '}
                {eventInfo[event].name}
              </span>
              <span className="checkmark">{savedEvents.has(event) ? '✔️' : <input type="radio" checked={selectedEvent === event} readOnly />}</span>
            </div>
          ))}
        </div>
        <div className="checklist-event-details">
          <div className="event-name-header">
            <h3>{emojiEventMapping[eventInfo[selectedEvent]?.name] || '🎉'} {' '} {eventInfo[selectedEvent]?.name}</h3>
            <button className="event-details-save-button" onClick={handleSave} disabled={!isEdited}>
              Save
            </button>
          </div>
          <div className="details-section">
            <div className="details-column">
              <h3>Details</h3>
              <div className="detail-item">
                <label>Event Name</label>
                <input type="text" value={details.name} readOnly />
              </div>
              <div className="detail-item">
                <label>Date & Time</label>
                <input
                  type="text"
                  name="dateTime"
                  value={details.dateTime}
                  onChange={handleDetailChange}
                />
              </div>
              <div className="detail-item">
                <label>Venue</label>
                <input
                  type="text"
                  name="venue"
                  value={details.venue}
                  onChange={handleDetailChange}
                />
              </div>
              <div className="detail-item">
                <label>Estimate Guest Count</label>
                <input
                  type="number"
                  name="guestCount"
                  value={details.guestCount}
                  onChange={handleDetailChange}
                />
              </div>
            </div>
            <div className="details-column">
            <h3>Vendors Needed</h3>
            <div className="vendor-item-container">
              {vendors.map((vendor) => {
                const vendorKey = vendor.toLowerCase().replace(/ /g, "").replace(/&/g, "And");
                return (
                  <div className="vendor-item" key={vendor} onClick={() => handleVendorChange(vendorKey)}>
                    <input
                      type="checkbox"
                      name={vendorKey}
                      checked={vendorsNeeded[vendorKey] || false}
                      onChange={() => handleVendorChange(vendorKey)}
                    />
                    <label className="checkbox-label">{vendor}</label>
                  </div>
                );
              })}
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
