import React, { useState } from 'react';
import './checklist-questions.css';
import { apiService } from '../../utils/apiService'; // Import your api service

const weddingPlanningQuestions = [
  { id: 1, question: "Have you created a guest list per event?" },
  { id: 2, question: "Have you created a master timeline of events?" },
  { id: 3, question: "Have you decided the venue for each event?" },
  { id: 4, question: "Have you decided your event catering?" },
  { id: 5, question: "Have you decided on a decorator for your events?" },
  { id: 6, question: "Have you decided on a photographer / videographer for your events?" },
  { id: 7, question: "Have you decided on a DJ for your events?" },
  { id: 8, question: "Have you decided on accommodations for out-of-town guests?" },
  { id: 9, question: "Have you decided on a hair & makeup artist?" },
  { id: 10, question: "Have you found a pundit for the ceremony?" },
  { id: 11, question: "Are you planning to shop for clothes in India?", isLastQuestion: true}
];

const ChecklistQuestions = ({setHasCompletedChecklistQuestions}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const userId = localStorage.getItem('userId'); // Assume userId is stored in localStorage

  // Handle when the user selects an option for a question
  const handleOptionClick = async (response) => {
    const currentQuestion = weddingPlanningQuestions[currentIndex];

    // Send the current question's response to the backend
    try {
      await apiService.post('api/user/checklistResponse', {
        userId,
        questionId: currentQuestion.id,
        isLastQuestion: currentQuestion.isLastQuestion,
        response // This is the selected response ('done', 'checklist', 'no-need')
      });

      // After successful response, move to the next question
      if (currentIndex < weddingPlanningQuestions.length - 1) {
        setCurrentIndex(currentIndex + 1);
        setProgress(((currentIndex + 1) / weddingPlanningQuestions.length) * 100);
      }

      if (currentQuestion.isLastQuestion) {
        setHasCompletedChecklistQuestions(true);
      }

      
    } catch (error) {
      console.error('Error saving response:', error);
    }
  };

  return (
    <div>
      <h2>Personalize Your Checklist</h2>
      <div>
        <div className="checklist-container">
          <div className="checklist-question-header">
            <div className="checklist-header-text">Answer a few more questions to get started</div>
          </div>
          <div className="checklist-questions-box">
            <div className="progress-bar-container">
              <p>Progress {currentIndex + 1} / {weddingPlanningQuestions.length}</p>
              <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
            <div className="question-box">
              <h3>{weddingPlanningQuestions[currentIndex].question}</h3>
            </div>
            <div className="options">
              <button className="btn-done" onClick={() => handleOptionClick('done')}>Already done!</button>
              <button className="btn-checklist" onClick={() => handleOptionClick('checklist')}>Add to my checklist</button>
              <button className="btn-no-need" onClick={() => handleOptionClick('no-need')}>I won't need this</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistQuestions;
