import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import WideButton from '../buttons/wide-button';
import './landing-screen.css'; // Ensure you use the new landing CSS file

function LandingScreen() {
  const navigate = useNavigate();

  const navigateToNextStep = () => {
    navigate('/onboarding'); // Adjust this to your next step
  };

  return (
    <div className="landing-container">

      {/* Main Landing Content */}
      <div className="landing-content">
      <div className="navbar">
        <Link to="/login" className="navbar-link login">Login</Link>
        <span className="navbar-divider">|</span>
        <Link to="https://planmydesiwedding.com/blog" className="navbar-link blog">Blog</Link>
        </div>

        {/* Bride Illustration */}
        <img src="/pmdw.png" alt="Bride Illustration" className="landing-illustration" />

        {/* Heading Text */}
        <h1 className="landing-heading">
          Hi! I’m Rani, ready to plan your Desi wedding?
        </h1>

        {/* Feature List */}
        <div className="landing-feature-list">
          <div className="feature-item">
            <span className="feature-icon">✅</span>
            <span>Get a checklist personalized to your wedding needs</span>
          </div>
          <div className="feature-item">
            <span className="feature-icon">💸</span>
            <span>Plan your expected spend & see how it compares</span>
          </div>
          <div className="feature-item">
            <span className="feature-icon">📝</span>
            <span>Learn tips & tricks that brides & grooms wish they knew before</span>
          </div>
        </div>

        {/* Get Started Button */}
        <div className="landing-bottom-button">
          <WideButton onClick={navigateToNextStep}>Get Started</WideButton>
        </div>
      </div>
    </div>
  );
}

export default LandingScreen;
