import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../context/auth-context';
import { apiService } from '../../utils/apiService';  // Import your apiService
import './login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { isAuthenticated, loading, login } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiService.post('api/auth/signin', {
                email,
                password,
            });

            if (response.data.token) {
                login(response.data.token);  // Store token and log in the user
                localStorage.setItem('userId', response.data.userId);
                
                navigate('/dashboard');
            } else {
                setError(response.data.message || 'Login failed');
            }
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="login-container">
            <img src="/pmdw.png" alt="PlanMyDesiWedding Logo" />
            <h1>PlanMyDesiWedding</h1>
            <form className="login-form" onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p className="error-message">{error}</p>}

                <button type="submit">Login</button>
                <div className="no-account">
                    <p>Don’t have an account? <Link to="/signup">Sign up here</Link>.</p>
                </div>
            </form>
        </div>
    );
};

export default Login;
