import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Onboarding from './components/onboarding/onboarding';
import LandingScreen from './components/onboarding/landing-screen';
import { AuthProvider, useAuth } from './context/auth-context';
import Dashboard from './components/dashboard/dashboard';
import Login from './components/authentication/login';
import ProtectedRoute from './components/authentication/protected-route';
import './index.css';

const RootRoute = () => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/welcome" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<RootRoute />} />
          <Route path="/login" element={<Login />} />
          <Route path="/welcome" element={<LandingScreen />} />
          <Route path="/onboarding" element={<Onboarding />} />

          {/* Protect Dashboard route */}
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
