import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { apiService } from '../../../utils/apiService';
import { getUserId } from '../../../utils/get-userid';
import TaskRow from './TaskRow'; // No need to memoize here
import './todo-list.css';
import { format, parse } from 'date-fns';
import CreatableSelect from 'react-select/creatable';

const predefinedEventOptions = [
  'Welcome Event', 'Haldi', 'Sangeet', 'Wedding Ceremony', 'Reception'
].map(event => ({ value: event, label: event }));

const predefinedCategoryOptions = [
  'Accommodations', 'Attire & Jewellery', 'Catering', 'Decor', 'DJ', 'Finances', 'Guest Communications',
  'Hair & Makeup', 'Logistics', 'Mehndi', 'Photography', 'Transportation', 'Venue', 'Videography', 'Wedding Planner', 'Gift Exchange'
].map(category => ({ value: category, label: category }));

const predefinedTypeOptions = [
  'Personal', 'Work', 'Miscellaneous'
].map(type => ({ value: type, label: type }));

const TodoList = () => {
  const [todos, setTodos] = useState([]);
  const todosRef = useRef(todos);
  const taskInputRefs = useRef({});
  const [openMonths, setOpenMonths] = useState({});
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const userId = getUserId();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const todoResponse = await apiService.get(`api/todo/${userId}`);
        if (todoResponse.success) {
          const todosData = todoResponse.data || [];
          setTodos(todosData);
          todosRef.current = todosData;
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);


    // Memoize unique types dynamically from the todos data
    const uniqueTypes = useMemo(() => {
      const typesSet = new Set(todos.map(todo => todo.type));
      return Array.from(typesSet).map(type => ({ value: type, label: type }));
    }, [todos]);


  const types = useMemo(() => predefinedTypeOptions, []);

  const categories = useMemo(() => predefinedCategoryOptions, []);

  const groupTodosByMonth = useCallback((todos) => {
    return todos.reduce((grouped, todo) => {
      if (todo.dueDate) {
        const monthKey = format(new Date(todo.dueDate), 'MMMM yyyy');
        if (!grouped[monthKey]) {
          grouped[monthKey] = [];
        }
        grouped[monthKey].push(todo);
      }
      return grouped;
    }, {});
  }, []);

  const filteredTodos = useMemo(() => {
    return todos.filter(task => {
      const matchesType = selectedTypes.length
        ? selectedTypes.some(type => type.value === task.type)
        : true;
      const matchesCategory = selectedCategories.length
        ? selectedCategories.some(category => category.value === task.category)
        : true;
      return matchesType && matchesCategory;
    });
  }, [todos, selectedTypes, selectedCategories]);

  const groupedTodos = useMemo(() => groupTodosByMonth(filteredTodos), [filteredTodos, groupTodosByMonth]);

  const toggleMonth = useCallback((month) => {
    setOpenMonths(prevOpenMonths => ({
      ...prevOpenMonths,
      [month]: !prevOpenMonths[month]
    }));
  }, []);

  const debouncedSaveTask = useCallback(
    debounce(async (updatedTask) => {
      try {
        const dataToUpdate = { ...updatedTask, taskId: updatedTask._id };
        await apiService.post('api/todo/updateTask', dataToUpdate);
        console.log("Task updated successfully");
      } catch (error) {
        console.error("Error updating task:", error);
      }
    }, 500), []);

  const handleTaskChange = useCallback((taskId, field, value) => {
    setTodos(prevTodos => {
      const updatedTodos = prevTodos.map(task =>
        task._id === taskId ? { ...task, [field]: value } : task
      );
      todosRef.current = updatedTodos;
      return updatedTodos;
    });

    const updatedTask = todosRef.current.find(task => task._id === taskId);
    if (updatedTask) {
      debouncedSaveTask(updatedTask);
    }
  }, [debouncedSaveTask]);

  const handleDeleteTask = useCallback(async (taskId) => {
    try {
      await apiService.post('api/todo/deleteTask', { userId, taskId });
      setTodos(prevTodos => prevTodos.filter(task => task?._id !== taskId));
      console.log("Task deleted successfully");
    } catch (error) {
      console.error("Failed to delete task:", error);
    }
  }, [userId]);

  const resizeTextArea = useCallback((textArea) => {
    if (textArea) {
      textArea.style.height = 'auto'; // Reset height
      textArea.style.height = `${textArea.scrollHeight}px`; // Set new height based on content
    }
  }, []);

  useEffect(() => {
    Object.values(taskInputRefs.current).forEach(resizeTextArea);
  }, [groupedTodos, resizeTextArea]);

  const handleAddTask = useCallback(async (month) => {
    const dueDate = parse(month, 'MMMM yyyy', new Date());
    const type = selectedTypes.length > 0 ? selectedTypes[0].value : 'Planning';
    const category = selectedCategories.length > 0 ? selectedCategories[0].value : 'General';

    const newTask = {
      task: '',
      type,
      category,
      progressNotes: '',
      proTip: '',
      dueDate,
      status: 'To-Do',
      userId
    };

    try {
      const response = await apiService.post('api/todo/addTask', newTask);
      if (response.success) {
        const savedTask = response.data;
        setOpenMonths(prevOpenMonths => ({
          ...prevOpenMonths,
          [month]: true
        }));
        setTodos(prevTodos => [savedTask, ...prevTodos]);

        // Focus on the new task input field
        setTimeout(() => {
          if (taskInputRefs.current[savedTask._id]) {
            taskInputRefs.current[savedTask._id].focus();
          }
        }, 100);
      } else {
        console.error('Failed to add task:', response.error);
      }
    } catch (error) {
      console.error('Error adding task:', error);
    }
  }, [selectedTypes, selectedCategories, userId]);

  return (
    <div className="todo-list">
      <div className="filter-container">
        <p>Filter by</p>
        <div className="filter-dropdowns">
          <div className='filter-group'>
            <label className='filter-label' htmlFor="type-filter">Type:</label>
            <CreatableSelect
              id="type-filter"
              isMulti
              placeholder="Type"
              value={selectedTypes || []}
              onChange={(selected) => setSelectedTypes(selected || [])}
              options={uniqueTypes}
            />
          </div>
          <div className='filter-group'>
            <label className='filter-label' htmlFor="category-filter">Category:</label>
            <CreatableSelect
              id="category-filter"
              placeholder="Category"
          
              isMulti
              value={selectedCategories || []}
              onChange={(selected) => setSelectedCategories(selected || [])}
              options={categories}
            />
          </div>
        </div>
      </div>

      {Object.keys(groupedTodos).map(month => (
        <div key={month} className="accordion-month">
          <div className="accordion-header" onClick={() => toggleMonth(month)}>
            <div className='accordion-header-left'>
              <span className={`accordion-arrow ${openMonths[month] ? 'open' : ''}`}>
                {openMonths[month] ? '▲' : '▼'}
              </span>
              <h2 className='accordian-month'>{month}</h2>
            </div>
            <button className="add-task-button" onClick={(e) => { e.stopPropagation(); handleAddTask(month); }}>
              Add Task
            </button>
          </div>
          {openMonths[month] && (
            <div className="accordion-content">
              <table className="task-table">
                <thead>
                  <tr>
                    <th className='type-column'>Type</th>
                    <th className="category-column">Category</th>
                    <th className="expanded-task-column">Task</th>
                    <th className="notes-column">Notes</th>
                    <th className="status-column"></th>
                    <th className="delete-column"></th>
                  </tr>
                </thead>
                <tbody>
                  {groupedTodos[month].map(task => (
                    <TaskRow
                      key={task?._id}
                      task={task}
                      types={uniqueTypes}
                      categories={categories}
                      handleTaskChange={handleTaskChange}
                      resizeTextArea={resizeTextArea}
                      handleDelete={handleDeleteTask}
                      inputRef={(el) => taskInputRefs.current[task._id] = el} // Store the input ref
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TodoList;
