import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../utils/apiService";
import Navigation from "./navigation";
import CostPlanner from "./cost-planner";
import { getUserId } from "../../utils/get-userid";
import Checklist from "../checklist/checklist";
import EventDetails from "../event-manager/event-details";
import "./dashboard.css";


const Dashboard = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [eventInfo, setEventInfo] = useState(null);
  const [activeTab, setActiveTab] = useState('cost-planner');
  const navigate = useNavigate();

  const [isLoadingEventInfo, setIsLoadingEventInfo] = useState(false);

  const userId = getUserId();

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!userId) {
        console.error("User ID not found in local storage");
        navigate("/onboarding");
        return;
      }
      const response = await apiService.get(`api/user/${userId}`);
      if (response.success) {
        setUserInfo(response.data);
      } else {
        console.error("Failed to fetch user info:", response.error);
      }

      const additionalEvents = response.data?.additionalEvents;
      const events = response.data?.events;

      if (!events || Object.keys(events).length === 0) {
        setIsLoadingEventInfo(true);
        const response = await apiService.post(`api/budget/create`, { userId, additionalEvents }, true);
        setEventInfo(response.data?.events);
        setIsLoadingEventInfo(false);
      } else {
        setEventInfo(response.data?.events);
      }
    };

    fetchUserInfo();
  }, [userId, navigate]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'event-manager':
        return eventInfo && <EventDetails />;
      case 'checklist':
        return eventInfo && <Checklist />;
      case 'cost-planner':
        return eventInfo && <CostPlanner eventInfo={eventInfo} setEventInfo={setEventInfo} userInfo={userInfo} />;

      default:
        return null;
    }
  };

  return (
    <div className="dashboard-wrapper">
      <Navigation userInfo={userInfo} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="onboarding-form-wrapper">
        {renderActiveTab()}
      </div>
    </div>
  );
};

export default Dashboard;
